
import { getSliceComponentProps } from '@prismicio/vue/components';
import getJSONLD from '@/utils/structured-data';
import accordionPushAdapter from '@/slices/AccordionPush/adapter';

export default {
  name: 'AccordionPush',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  head() {
    return {
      script: [getJSONLD('faq', this.sliceData)],
    };
  },
  computed: {
    sliceData() {
      return accordionPushAdapter(this.slice);
    },
  },
};
