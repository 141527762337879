import { richText, stripRichTextWrapperTag } from '@/utils/html';

export const sanitizeItems = (items) =>
  items.map((item) => ({
    head: item.accordion_push_item_title,
    text: richText(item.accordion_push_item_description),
  }));

export default ({ primary, items }) => ({
  isFAQ: primary.accordion_push_isfaq,
  title: stripRichTextWrapperTag(primary.accordion_push_title),
  items: sanitizeItems(items),
});
